<template>
  <div class="approval-page">
    <!-- Main loading state for the entire page -->
    <div
      v-if="pageLoading"
      class="page-loader-container"
    >
      <div class="ui active dimmer">
        <div class="ui large text loader">Loading...</div>
      </div>
    </div>

    <!-- Only render the rest of the content when page is ready -->
    <template v-else>
      <!-- Show centralized error message if there's an error -->
      <div
        v-if="error"
        class="error-container"
      >
        <div class="ui negative message">
          <div class="header">Error</div>
          <p>{{ error }}</p>
        </div>
      </div>

      <!-- Show normal split view layout when no errors -->
      <template v-else>
        <div class="sidebar-container">
          <sidebar
            :disabled="isLoading"
            :current-view-type="currentViewType"
            :approval-data="approvalData"
            :current-asset-data="currentAssetData"
            :is-submitting="isSubmitting"
            :is-submitted="isSubmitted"
            :selected-action="selectedAction"
            :comment="comment"
            :available-actions="availableActions"
            @fetch-single="handleFetchSingle"
            @fetch-step-repeat="handleFetchStepRepeat"
            @update-selected-action="selectedAction = $event"
            @update-comment="comment = $event"
            @submit-action="submitAction"
          />
        </div>
        <div class="viewer-container">
          <viewer
            v-if="viewerUrl"
            :viewer-url="viewerUrl"
          />
          <div
            v-else
            class="ui placeholder segment"
          >
            <div
              v-once
              class="ui icon header"
              aria-label="No document preview available"
            >
              <i
                class="file outline icon"
                aria-hidden="true"
              ></i>
              No document preview available
            </div>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script setup>
import {ref, onMounted, onUnmounted, watch, computed, defineAsyncComponent, h} from 'vue'
import {useDocumentVisibility} from '@vueuse/core'
import {useRoute, useRouter} from 'vue-router'
import backendApiClient from '@/services/backendApiClient'
import authenticatedApiClient from '@/services/authenticatedApiClient'
import {storeImports} from '@/stores/storeImports'
import {useWaitingScreen} from '@/composables/useWaitingScreen'

// Inline minimal loading and error components for async components
const LoadingComponent = {
  name: 'AsyncLoading',
  render() {
    return h('div', {class: 'ui active inline loader'})
  }
}

const ErrorComponent = {
  name: 'AsyncError',
  render() {
    return h('div', {class: 'ui negative message'}, 'Failed to load component.')
  }
}

// Lazy load large components with loading/error states
const Sidebar = defineAsyncComponent({
  loader: () => import('./Partials/Sidebar.vue'),
  loadingComponent: LoadingComponent,
  errorComponent: ErrorComponent,
  delay: 200,
  timeout: 10000
})

const Viewer = defineAsyncComponent({
  loader: () => import('./Partials/Viewer.vue'),
  loadingComponent: LoadingComponent,
  errorComponent: ErrorComponent,
  delay: 200,
  timeout: 10000
})

// Initialize waiting screen composable
const {appendWaitingScreen} = useWaitingScreen()

// Base state
const route = useRoute()
const router = useRouter()
const visibility = useDocumentVisibility()
/** @type {import('vue').Ref<import('@/stores/user').UserStore|null>} */
const userStore = ref(null)

// API state
const apiClient = computed(() =>
  userStore.value?.isAuthenticated ? authenticatedApiClient : backendApiClient
)

// Core approval data
/** @type {import('vue').Ref<string>} */
const token = ref('')
const approvalData = ref(null)
const availableActions = ref([])

// Current asset data based on the selected view type
const currentAssetData = computed(() => {
  if (!approvalData.value) return null

  return approvalData.value.file
})

// URL and view state
const rawViewerUrl = ref('')
const viewerUrl = computed(() => appendWaitingScreen(rawViewerUrl.value))
const currentViewType = ref('single')
const updateUrlQuery = type => {
  router.replace({query: {...route.query, view: type}})
}

// UI state
const pageLoading = ref(true)
const isLoading = ref(false)
const error = ref(null)
const cssImportsLoaded = ref(false)

// Form state
const selectedAction = ref('')
const comment = ref('')
const isSubmitting = ref(false)
const isSubmitted = ref(false)
const isActionFormOpen = ref(true)

// Polling
let pollingInterval = null

// Fetch approval data using the token
/**
 * Process approval API response and update reactive state
 * @param {any} response
 * @param {'single'|'stepRepeat'} viewType
 */
function processApprovalResponse(response) {
  const data = response.data

  // console.log('Approval data file viewer URL:', data.file.viewer_url, typeof data.file.viewer_url)
  if (!data || typeof data !== 'object' || !data.file || typeof data.file !== 'object') {
    approvalData.value = null
    rawViewerUrl.value = ''
    error.value = 'Invalid approval data received from server.'
    return
  }

  approvalData.value = data

  // Update available actions
  if (response.data.available_actions && Array.isArray(response.data.available_actions)) {
    availableActions.value = response.data.available_actions
  }

  updateViewerUrl()

  // Update submission status
  isSubmitted.value = ['approved', 'rejected'].includes(response.data.status)
}

/**
 * Resolve viewer URL based on view type and approval data
 * @param {'single'|'stepRepeat'} type
 * @param {any} data
 * @returns {string}
 */
function updateViewerUrl() {
  if (
    approvalData.value &&
    approvalData.value.file &&
    typeof approvalData.value.file === 'object' &&
    approvalData.value.file.viewer_url
  ) {
    rawViewerUrl.value = approvalData.value.file.viewer_url
    error.value = null
  } else {
    rawViewerUrl.value = ''
    error.value = 'No file URL available in the approval data.'
  }
}

/**
 * Fetch approval data initially
 * @param {string} tokenValue
 */
const fetchApprovalData = async tokenValue => {
  isLoading.value = true
  error.value = null

  try {
    const response = await apiClient.value.get(
      `/api/v1/approval-links/${tokenValue}?view=${currentViewType.value}`
    )
    processApprovalResponse(response)
  } catch (err) {
    console.error('Failed to fetch approval data:', err)

    if (err.response?.status === 404) {
      error.value = 'This link has expired or was already used.'
    } else if (err.response?.status === 401) {
      error.value = 'You are not authorized to access this link.'
    } else {
      error.value = 'Failed to load data. Please try again later.'
    }
  } finally {
    isLoading.value = false
    pageLoading.value = false
  }
}

// Function to fetch updated approval data
const fetchApprovalUpdates = async () => {
  if (!token.value || pageLoading.value) return

  try {
    const response = await apiClient.value.get(
      `/api/v1/approval-links/${token.value}?view=${currentViewType.value}`
    )
    processApprovalResponse(response)
  } catch (err) {
    console.error('Polling failed:', err)

    if (err.response) {
      if (err.response.status === 404) {
        error.value = 'This approval link has expired or was already used.'
        isSubmitted.value = true
      } else if (err.response.status === 401) {
        error.value = 'You are not authorized to access this approval link.'
      } else {
        error.value = 'Failed to update approval data. Please try again later.'
      }
    } else {
      error.value = 'Network error. Please check your connection and try again.'
    }
  }
}

// Submit approval action
const submitAction = async () => {
  if (!token.value || !selectedAction.value) return

  isSubmitting.value = true

  try {
    const payload = {
      action: selectedAction.value,
      comment: comment.value
    }

    await apiClient.value.post(`/api/v1/approval-links/${token.value}/submit`, payload)

    // Update local state
    isSubmitted.value = true
    approvalData.value = {
      ...approvalData.value,
      status: selectedAction.value === 'approve' ? 'approved' : 'rejected'
    }

    // Close the form accordion
    isActionFormOpen.value = false
  } catch (err) {
    console.error('Failed to submit approval action:', err)

    if (err.response?.data?.message) {
      error.value = err.response.data.message
    } else {
      error.value = 'Failed to submit your decision. Please try again later.'
    }
  } finally {
    isSubmitting.value = false
  }
}

// View type handlers
const handleFetchSingle = () => {
  if (currentViewType.value === 'single') return
  currentViewType.value = 'single'
  updateUrlQuery('single')
  fetchApprovalData(token.value)
}

const handleFetchStepRepeat = () => {
  if (currentViewType.value === 'stepRepeat') return
  currentViewType.value = 'stepRepeat'
  updateUrlQuery('stepRepeat')
  fetchApprovalData(token.value)
}

// Watch for visibility changes
watch(visibility, isVisible => {
  if (isVisible) {
    fetchApprovalUpdates() // Fetch immediately when tab becomes visible
  }
})

// Initialize page on mount
onMounted(async () => {
  pageLoading.value = true

  try {
    // Load user store to check authentication status
    userStore.value = await storeImports.user()

    // Load CSS files if needed (only for non-authenticated users)
    await loadCssFiles()

    const tokenValue = route.params.token
    if (!tokenValue) {
      error.value = 'No approval token provided in the URL.'
      isLoading.value = false
      pageLoading.value = false // End loading on error
      return
    }
    token.value = tokenValue

    // Read initial view type from URL query, default to 'single'
    const initialType = route.query.view === 'stepRepeat' ? 'stepRepeat' : 'single'
    currentViewType.value = initialType

    // Fetch initial approval data
    fetchApprovalData(token.value)

    // Ensure URL reflects the initial state if not already set
    if (!route.query.view || route.query.view !== currentViewType.value) {
      updateUrlQuery(currentViewType.value)
    }

    // Set up the polling interval but don't fetch immediately (we already fetched above)
    pollingInterval = setInterval(() => {
      if (!visibility.value) return
      if (isSubmitted.value) return
      if (pageLoading.value) return
      fetchApprovalUpdates()
    }, 30000) // Poll every 30 seconds
  } catch (error) {
    console.error('Error during initialization:', error)
    pageLoading.value = false // Make sure loading ends even on error
  }
})

// Clean up resources on unmount
onUnmounted(() => {
  if (pollingInterval) {
    clearInterval(pollingInterval)
  }
})

const loadCssFiles = async () => {
  if (cssImportsLoaded.value) return

  try {
    if (!userStore.value?.isAuthenticated) {
      await Promise.all([
        import('@/vendor/fomantic/dist/components/button.min.css'),
        import('@/vendor/fomantic/dist/components/accordion.min.css'),
        import('@/vendor/fomantic/dist/components/transition.min.css'),
        import('@/vendor/fomantic/dist/components/loader.min.css'),
        import('@/vendor/fomantic/dist/components/form.min.css'),
        import('@/vendor/fomantic/dist/components/message.min.css'),
        import('@/vendor/fomantic/dist/components/segment.min.css'),
        import('@/vendor/fomantic/dist/components/dimmer.min.css'),
        import('@/vendor/fomantic/dist/components/list.min.css'),
        import('@/vendor/fomantic/dist/components/header.min.css'),
        import('@/vendor/fomantic/dist/components/placeholder.min.css'),
        import('@/vendor/fomantic/dist/components/table.min.css')
      ])
    }

    cssImportsLoaded.value = true
  } catch (err) {
    console.error('Failed to load CSS files:', err)
    error.value = 'Some styles failed to load. The page may not display correctly.'
  }
}
</script>

<style lang="scss" scoped>
.approval-page {
  display: grid;
  grid-template-columns: 350px 1fr;
  height: calc(
    100vh - var(--header-height, 0px)
  ); // Use CSS variable for header height, fallback to 0px
  margin: 0;
  overflow: hidden;

  // Page loader container styles
  .page-loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;

    .ui.active.dimmer {
      background-color: rgba(255, 255, 255, 0.95);
      box-shadow: none;
    }

    .ui.large.text.loader {
      font-size: 1.2em;
      color: var(--primary-color, #4183c4);
      &:before {
        border-color: var(--primary-color, #4183c4) transparent transparent;
      }
      &:after {
        border-color: var(--primary-color, #4183c4) transparent transparent;
      }
    }
  }

  // When there's an error or during loading, we need to reset the grid layout
  &:has(.error-container),
  &:has(.loader-container) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loader-container,
  .error-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .ui.message {
      max-width: 500px;
      margin: 0;
    }

    .ui.active.dimmer {
      background-color: rgba(255, 255, 255, 0.9);
    }
  }

  .sidebar-container {
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #f9f9f9;
    border-right: 1px solid #ddd;
    position: relative;
    width: 350px; // Fixed width for the sidebar
    min-width: 350px; // Ensure minimum width is maintained
    max-width: 350px; // Ensure maximum width is maintained

    .ui.active.dimmer {
      z-index: 10;
    }

    :deep(.sidebar) {
      height: 100%;
      margin: 0;
      border-radius: 0;
    }
  }

  .viewer-container {
    padding: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e0e0e0;

    :deep(iframe),
    :deep(.viewer-component-root) {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
